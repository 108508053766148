import React, {FormEvent, useState} from "react";
import {Button, Container, Form, FormField, Header, Input, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {NonCancelableCustomEvent} from "@amzn/awsui-components-react";

export const SetOtpPinForm = ({loggedInUsername, error, onSubmit}: SetOtpPinFormProps) => {
    const [pinValue, setPinValue] = useState('')
    const [pinError, pinSetError] = useState<string | null>(null);
    const handlePinChange = (e: NonCancelableCustomEvent<{ value: string }>) => {
        const asciiRegex = /^[!-~]{8,}$/;
        const value = e.detail.value;
        setPinValue(value);
        if (value.trim() === '') {
            pinSetError('Setting pin is required');
        } else if (value.length < 8) {
            pinSetError('Pin must be at least 8 characters long');
        } else if (!asciiRegex.test(value)) {
            pinSetError('Pin must consist of only ASCII characters');
        } else {
            pinSetError(null);
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (pinError || pinValue.trim() === "") {
            alert('Please fix the errors before submitting');
        }
        onSubmit(pinValue);
    }

    return (
        <Container header={<Header variant="h2">Set your OTP security pin</Header>}>
            <form style={{margin: '16px'}} onSubmit={handleSubmit}>
                <Form
                    actions={<Button variant="primary" data-testid="set-button" disabled={!!pinError || pinValue === ''}>Set</Button>}
                    errorText={error}
                >
                    <SpaceBetween direction="vertical" size="m">
                        <p>You are setting OTP pin for <strong>{loggedInUsername}</strong>. Enter a valid PIN
                            and then click the "Set". Pin must at least 8 characters.
                            button.</p>
                            <FormField label={"Enter pin"}>
                                <Input type="password" value={pinValue} onChange={handlePinChange}/>
                            </FormField>
                            {pinError && <p style={{color: 'red'}}>{pinError}</p>}
                </SpaceBetween>
                </Form>
            </form>
        </Container>
    )
}