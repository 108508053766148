import React, {FormEvent, useState} from "react";
import {Button, Container, Form, FormField, Header, Input, NonCancelableCustomEvent, SpaceBetween} from "@amzn/awsui-components-react/polaris";

export const RegisterOtpKeyForm = ({loggedInUsername, error, onSubmit}: RegisterOtpKeyFormProps) => {
    const [pinValue, setPinValue] = useState('')
    const [otpValue, setOtpValue] = useState('')
    const [otpError, otpSetError] = useState<string | null>(null);
    const handlePinChange = (event: NonCancelableCustomEvent<{ value: string }>) => {
        setPinValue(event.detail.value);
    };
    const handleOtpChange = (event: NonCancelableCustomEvent<{ value: string }>) => {
        const value = event.detail.value;
        setOtpValue(value);
        if (value.trim() === '') {
            otpSetError('OTP is required');
        } else if (value.length !== 44) {
            otpSetError('Unexpected character length of OTP');
        } else {
            otpSetError(null);
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (otpError || pinValue.trim() === "" || otpValue.trim() === "") {
            alert('Please fix the errors before submitting');
        }
        onSubmit(pinValue, otpValue);
    }

    return (
        <Container header={<Header variant="h2">Register a new OTP security key</Header>}>
            <form onSubmit={handleSubmit}>
                <Form
                    actions={<Button variant="primary" data-testid="register-button" disabled={pinValue.trim() === ''}>Register</Button>}
                    errorText={error}
                >
                    <SpaceBetween direction="vertical" size="s">
                        <p>You are registering an OTP key for <strong>{loggedInUsername}</strong>. Enter a valid PIN and then click the "Register"
                            button below to begin the key registration process.</p>
                        <FormField label="Enter pin:">
                            <Input type="password" value={pinValue} onChange={handlePinChange}/>
                        </FormField>
                        <FormField label="Press and hold YubiKey:">
                            <Input type="password" value={otpValue} onChange={handleOtpChange}/>
                        </FormField>
                        {otpError && <p style={{color: 'red'}}>{otpError}</p>}
                    </SpaceBetween>
                </Form>
            </form>
        </Container>
    )
}