import React from "react";
import {useAuth} from "react-oidc-context";
import {Box, Container, ContentLayout, Header, Link, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";

export const Home = () => {
    const auth = useAuth();

    const welcome = auth.user?.profile?.name ? `Welcome, ${auth.user?.profile.name}!` : "Welcome!";
    return (
        <PageLayout loggedInUsername={auth.user?.profile?.name} otpEnabled={auth.user?.profile?.otpEnabled as boolean ?? false}>
            <ContentLayout
                headerVariant="high-contrast"
                header={<Header variant="h1">{welcome}</Header>}
                defaultPadding={true}
                maxContentWidth={1040}
                disableOverlap={false}
            >
                <Container>
                    <SpaceBetween size="m">
                        <div>
                            <Header variant="h3">Kuiper Internal Identity Provider</Header>
                            <Box variant="p">
                                The Kuiper Internal Identity Provider (IdP) is the system responsible for answering the
                                question of authentication: Is the user/automation/system that claims to be, actually
                                the user they claim to be?
                            </Box>
                            <Link
                                href="https://w.amazon.com/bin/view/KuiperEnterpriseTechnology/KAAP/KIAM/KILM#HIdentityProvider28IdP29"
                                external={true} variant="primary"
                                ariaLabel="Learn more about the Kuiper Identity Provider">
                                Learn more
                            </Link>
                        </div>
                    </SpaceBetween>
                </Container>
            </ContentLayout>
        </PageLayout>
    );
}